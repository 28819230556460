import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { SocketService } from '../shared/socket.service';
import { UtilTGT } from '../shared/utilTGT';
import { Repositorio } from '../shared/repositorio';
import * as moment from "moment";
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'lista-socket',
  templateUrl: './lista-socket.component.html',
  styleUrls: ['./lista-socket.component.css']
})
export class ListaSocketComponent implements OnInit 
{
  listaDados: any[] = [];
  dataSource: any;
  colunasDataSource = ['Sistema', 'Distribuidora', 'CodigoVendedor'];
  
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('pesquisa', {static: true}) pesquisa: ElementRef;
  
  constructor
  (
    private socketService: SocketService,
    private repositorio: Repositorio
  ) 
  { 
  }

  ngOnInit()
  {    
    this.retornarUsuarios();

    setInterval(() => { this.retornarUsuarios();}, this.repositorio.tempoSincronizacaoUsuarios);
  }

  applyFilter(filterValue: string) 
  {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    this.dataSource.filter = filterValue;
  }

  async retornarUsuarios()
  {
    // Carrega as informações da distribuidora
    if(this.repositorio.listaDistribuidoraEmpresa.length == 0)
    {
      // Carrega as informações das distribuidoras
      var data = {Token: 'RETAGUARDATGT', IdVendedor: '', CodigoVendedor: 'RETAGUARDATGT', CNPJ: '', Acao: 'dadosDistribuidoraEmpresa', Parametros: '', Sistema: 1};

      // Encrypt
      var msg = btoa(JSON.stringify(data));
      let retorno = await this.socketService.enviarMensagem('mobSuperMensagemCliente', msg);

      if(retorno.dados != '')
      {
        this.repositorio.listaDistribuidoraEmpresa = UtilTGT.tratarJson(retorno.dados).MensagemRetorno;
      }
    } 

    console.log('Retornar usuarios');

    this.listaDados = [];
    this.dataSource = [];
    this.pesquisa.nativeElement.value = '';
    let dataAtual = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    var data = {Token: 'RETAGUARDATGT', IdVendedor: '', CodigoVendedor: 'RETAGUARDATGT', CNPJ: '', Acao: '', Parametros: '', Sistema: 1};

    // Encrypt
    var msg = btoa(JSON.stringify(data));
    let retorno = await this.socketService.enviarMensagem('clientesConectados', msg);

    console.log('Retornar usuarios - retorno');
    console.log(retorno);

    if(retorno.dados != '')
    {
      let resultado = UtilTGT.tratarJson(retorno.dados);

      for(let i=0; i< resultado.length; i++)
      {
        if (resultado[i].CodigoVendedor != 'RETAGUARDATGT')
        {
          let tipo = resultado[i].TipoCliente == 1 ? 'RETAGUARDA' : 'APP';

          resultado[i].Sistema = resultado[i].Sistema == 1 ? 'MOB SUPERVISOR - '+ tipo : '? - '+ tipo;
          resultado[i].CodigoVendedor = resultado[i].CodigoVendedor == '-1' ? '' : resultado[i].CodigoVendedor;

          // Pesquisa a Distribuidora
          let pesquisa = this.repositorio.listaDistribuidoraEmpresa.find(x => x.CNPJ == resultado[i].CNPJ);

          if(pesquisa != undefined && pesquisa != null)
          {
            resultado[i].Distribuidora = pesquisa.Nome + ' ('+ resultado[i].CNPJ + ')';
          }
          else
          {
            resultado[i].Distribuidora = '? ('+ resultado[i].CNPJ + ')';
          }
          
          this.listaDados.push(resultado[i]);
        }
      }      

      this.repositorio.statusSocket.data = dataAtual;
    }

    this.dataSource = new MatTableDataSource(this.listaDados);
    this.dataSource.sort = this.sort;
  }
}