import * as moment from "moment";

export enum TipoCliente 
{
    RETAGUARDA = 1,
    CLIENTE = 2,
}

export enum Sistema 
{
    MOBSUPERVISOR = 1
}

export class UtilTGT
{
    static replace(str, find, replace) 
    {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    static replaceAll(find, replace, str) 
    {
      if(str == null)
      {
        return null;
      }

      while( str.indexOf(find) > -1)
      {
        str = str.replace(find, replace);
      }

      return str;
    }
    
    static retornarDiaSemana(date: Date) : any
    {
        let semana = new Array(7);
        semana[0] = "SEG";
        semana[1] = "TER";
        semana[2] = "QUA";
        semana[3] = "QUI";
        semana[4] = "SEX";
        semana[5] = "SAB";
        semana[6] = "DOM";

        return semana[date.getDay()];
    }

    static toFixed(numero: any, casas: number) 
    {
        let inteiro = numero.toString().split('.')[0];
        let decimal = numero.toString().split('.')[1].substring(0, casas);

        // Completa com 0 caso o numero decimal for menor que o numero de casas
        while(decimal.length < casas)
        {
            decimal += "0";
        }

        let resultado = inteiro +'.'+ decimal;

        return resultado;
    }

    static tratarJson(data)
    {
      // preserve newlines, etc - use valid JSON
      let s = data.toString().replace(/\\n/g, "\\n")  
               .replace(/\\'/g, "\\'")
               .replace(/\\"/g, '\\"')
               .replace(/\\&/g, "\\&")
               .replace(/\\r/g, "\\r")
               .replace(/\\t/g, "\\t")
               .replace(/\\b/g, "\\b")
               .replace(/\\f/g, "\\f");
      // remove non-printable and other non-valid JSON chars
      s = s.replace(/[\u0000-\u0019]+/g,"");
   
      let obj = JSON.parse(s);

      return obj;
    }

    static senhaSuperDia()
    {
      let strData = moment(new Date()).format('YYYYMMDD');
      let strItem1 = strData.substring(0, 1) + strData.substring(2, 3) + strData.substring(4, 5) + strData.substring(6, 7);
      let strItem2 = strData.substring(7, 8) +''+ strData.substring(5, 6) +''+ strData.substring(3, 4) +''+ strData.substring(1, 2);
      let raizQuadrada = Math.sqrt(parseInt(strItem1) * parseInt(strItem2));
      let mod = (raizQuadrada % 1).toString().replace('0.', '');
      let numero = this.zeroPad(mod, 8);

      return numero;
    }

    private static zeroPad(numero, places) 
    {
      if(numero.length >= places)
      {
        return numero.substring(0, places);
      }

      var zero = places - numero.toString().length + 1;
      return numero + Array(+(zero > 0 && zero)).join("0");
    }
}

export default UtilTGT;