import { Injectable } from '@angular/core';

@Injectable()
export class Repositorio
{
    statusSocket: any =
    {
      online: false,
      mensagem: '',
      data: ''
    };

    listaDistribuidoraEmpresa: any[] = [];
    tempoSincronizacao: number = 1 * (60 * 1000); // minutos - milesegundos
    tempoSincronizacaoUsuarios: number = 5 * (60 * 1000); // minutos - milesegundos
    autenticado: boolean = false;
}