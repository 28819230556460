import { Component, OnInit } from '@angular/core';
import { ApiConService } from '../shared/api-con.service';
import { Repositorio } from '../shared/repositorio';
import { SocketService } from '../shared/socket.service';
import { UtilTGT } from '../shared/utilTGT';
import * as moment from "moment";
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'status-socket',
  templateUrl: './status-socket.component.html',
  styleUrls: ['./status-socket.component.css']
})
export class StatusSocketComponent implements OnInit 
{
  constructor
  (
    private conService: ApiConService,
    public repositorio: Repositorio,
    private socketService: SocketService
  ) 
  { 
    moment.locale('pt-BR');
  }

  ngOnInit() 
  {    
    console.log('aqui 1---------------------------------------------------');

    this.carregarInformacoes();
    
    setInterval(() => { this.carregarInformacoes();}, this.repositorio.tempoSincronizacao);
  }

  async carregarInformacoes()
  {
    console.log('aqui 2---------------------------------------------------');

    // Carrega as informações do serviço
    let dataAtual = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    const resposta = await this.conService.get();

    if(resposta && resposta["message"])
    {
      this.repositorio.statusSocket.online = true;
      this.repositorio.statusSocket.mensagem = resposta["message"];
    }
    else
    {
      this.repositorio.statusSocket.online = false;
      this.repositorio.statusSocket.mensagem = 'Erro ao conectar no serviço';
    }

    this.repositorio.statusSocket.data = dataAtual;
  }
}