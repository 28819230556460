import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListaSocketComponent } from './lista-socket/lista-socket.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import 
{ 
  MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatToolbarModule, MatTableModule, MatSortModule, 
  MatCardModule, MatDialogModule, MatMenuModule, MatProgressSpinnerModule 
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ApiConService } from './shared/api-con.service';
import { HttpClientModule } from '@angular/common/http';
import { StatusSocketComponent } from './status-socket/status-socket.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SocketService } from './shared/socket.service';
import { Repositorio } from './shared/repositorio';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    ListaSocketComponent,
    StatusSocketComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,    
    AppRoutingModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule, 
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatCardModule, 
    MatDialogModule, 
    MatMenuModule, 
    MatProgressSpinnerModule,
    FlexLayoutModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: 
  [
    ApiConService,
    SocketService,
    Repositorio
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
