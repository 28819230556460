import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiConService 
{
  token: any;

  constructor (private http: HttpClient) { }

  public async get(instancia?: string)
  { 
    let url = instancia != undefined && instancia != null ? environment.restApiURL + instancia : environment.restApiURL;

    console.log(url);

    //this.gerarToken();
    return await this.http.get(url).toPromise();
  }

  private gerarToken()
  {        
      this.token = 
      {
          headers: new HttpHeaders
          ({
              //'Authorization': btoa('{"usuarioLogado": "'+ environment.usuarioLogado +'", "baseDados":"'+ environment.baseDados +'", "servidor":"'+ environment.servidor +'"}')
          })
      };
  }  
}
