import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material'
import UtilTGT from '../shared/utilTGT';
import { Repositorio } from '../shared/repositorio';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor
  (
    private repositorio: Repositorio,
    private matDialog: MatDialog
  ) { }

  password: string;

  ngOnInit() 
  {}

  logar() : void 
  {
    let senhaAux = 3902526700 + new Date().getDate();

    if(this.password == UtilTGT.senhaSuperDia() || this.password == senhaAux.toString())
    {
      this.repositorio.autenticado = true;
    } 
    else 
    {
      alert("Dados inválidos!");
    }
  }
}