import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as io from 'socket.io-client';

@Injectable()
export class SocketService 
{
  static socket: SocketIOClient.Socket;
  static retornoDados = { dados: '', erro: '' };

  constructor() 
  {
    let data = btoa(JSON.stringify({Token: 'RETAGUARDATGT', IdVendedor: '', ChaveDispositivo: '', CodigoVendedor: 'RETAGUARDATGT', CNPJ: '', Acao: '', Sistema: 1}));
    SocketService.socket = io('http://35.238.78.161:36736', { query: "token="+ data } );
    //SocketService.socket = io('http://socketio.paineltarget.net.br:36736', { query: "token="+ data } );
    //SocketService.socket = io('http://azure.paineltarget.com.br:36736', { query: "token="+ data } ); // Kin
  }

    async enviarMensagem(tipo: any, data: any)
    {
        SocketService.retornoDados = { dados: '', erro: '' };

        // Retorna
        let resultado = await new Promise(function(resolve, reject) 
        {   
            SocketService.socket.emit(tipo, data, (response) => 
            {
                if (response.error) 
                {
                    SocketService.retornoDados.erro = response.error;
                    resolve('');
                } 
                else 
                {
                    // Descriptografa a mensagem
                    SocketService.retornoDados.dados = atob(response);
                    resolve('');
                }
            });
        });

        return SocketService.retornoDados;
    }
}