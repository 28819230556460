import { Component } from '@angular/core';
import { Repositorio } from './shared/repositorio';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TargetSocket';

  constructor
  (
    public repositorio: Repositorio
  ) { }
}